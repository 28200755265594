<template>
    <div class="relative" style="z-index: 9999;" :aria-labelledby="getBasketDeleteType == 'scripDelete' ? 'Del_scrip_title' : 'Del_basket_title'" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-black bg-opacity-25 transition-opacity"></div>
        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden rounded-lg bg-white dark:bg-zinc-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-4">
                    <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <button @click="$store.commit('setDeleteScripDialog', false)" type="button"
                            class="rounded-md bg-white  secondary-color hover:text-[#56585A] focus:outline-none">
                            <span class="sr-only">Close</span>
                            <svg class="h-6 w-6 dark:bg-zinc-800 dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="sm:flex sm:items-start">
                        <div class="mt-3 text-center sm:mt-0 sm:text-left">
                            <h3 class="text-lg font-medium leading-6 primary-color" :id="getBasketDeleteType == 'scripDelete' ? 'Del_scrip_title' : 'Del_basket_title'">Delete</h3>
                        </div>
                    </div>
                    <div :id="getBasketDeleteType == 'scripDelete' ? 'Del_scrip_text' : 'Del_basket_text'" class="mt-5 mb-6 primary-color" v-if="getBasketDeleteType == 'scripDelete'">
                        Are you sure want to delete this scrip <span class="font-semibold">{{ getBasketDeleteData.formattedInsName }}</span> ? 
                    </div>
                    <div class="mt-5 mb-6 primary-color" v-else>
                        Are you sure want to delete this Basket <span class="font-semibold">{{ getBasketDeleteData.basketName }}</span> ? 
                    </div>
                    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button :id="getBasketDeleteType == 'scripDelete' ? 'Del_scrip_confirm_btn' : 'Del_basket_confirm_btn'" @click="deleteScrip()" type="button"
                            class="inline-flex w-full justify-center rounded-md border border-transparent violet-bg px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm">Confirm</button>
                        <button :id="getBasketDeleteType == 'scripDelete' ? 'Del_scrip_cancel_btn' : 'Del_basket_cancel_btn'" @click="$store.commit('setDeleteScripDialog', false)" type="button"
                            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium primary-color shadow-sm hover:text-[#56585A] focus:outline-none sm:mt-0 sm:w-auto sm:text-sm">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    }, 
    methods: {
        async deleteScrip() {
            if(this.getBasketDeleteType == 'basketDelete') {
                this.$store.dispatch('basket/deleteBasket', { id: this.getBasketDeleteData.basketId })
            } else {
                this.$store.dispatch('basket/deleteScripInBasket', this.getBasketDeleteData)
            }
        }
    },
    computed: {
        ...mapGetters(['getBasketDeleteType','getBasketDeleteData'])
    },
}
</script>