<template>
    <div>
        <div v-if="getTradeBook.length != 0">
        <div class="flex flex-col">
            <div class="overflow-x-auto" :class="showDropDown ? 'overflowinherit' : ''">
                <div class="inline-block min-w-full align-middle">
                    <div class="relative"  :class="showDropDown ? 'overflowinherit' : 'overflow-y-auto'">
                        <!-- border-t -->
                        <table id="tradebook_table" class="min-w-full table-fixed rounded-b border-b border-[#ededed] dark:border-[#232325] order-table" >
                            <thead class="border-b dark:border-[#232325]">
                                <tr>
                                     <th @mouseover="isHover = true;currentIdx = idx" @mouseleave="isHover = false;currentIdx = -1" @click="changeSort(head.value)" scope="col" v-for="(head, idx) of tableHead" :key="idx" :class="[head.class,{ 'bg-slate-50 dark:bg-zinc-800 cursor-pointer' :isHover && currentIdx == idx && head.sortable}]" >
                                            <span  :class="head.alignment">
                                                <span class="whitespace-nowrap" :id="`tradebook_th_${head?.text}`">{{ head?.text }}</span>
                                                <span v-if="head.sortable" class="min-w-[12px] mx-1">
                                                    <span v-if="isHover && currentIdx == idx">
                                                        <span v-if="head.isdesc">
                                                            <img :src="getIsTheme == 1 ? upArrowDark : upArrow" alt="upArrow">
                                                        </span>
                                                        <span v-if="!head.isdesc">
                                                            <img :src="getIsTheme == 1 ? downArrowDark : downArrow" alt="downArrow">
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, idx) in getSearch == '' ? getTradeBook : filterData" :key="idx" @mouseover="currentIndex = idx"
                                    @mouseleave="currentIndex = -1; showDropDown = false" :id="`tradebooklist_${idx}`" class="border-b border-[#ededed] dark:border-[#232325]">
                                    <td  class="w-[80px] py-4 px-2 text-sm" :id="`tradebook_td_${idx}_time`">
                                        {{ item.orderTime?.split(' ')[0] }}
                                    </td>
                                    <td class="whitespace-nowrap text-center px-3 py-4" :id="`tradebook_td_${idx}_type`">
                                        <button :class="$common.getTypeTdClass(item.transType)" class="px-3 py-1 rounded">{{  item.transType == 'B' ? 'BUY' : 'SELL' }}</button>
                                    </td>
                                    <td class="px-2 py-4 text-sm relative min-w-[200px]" :id="`scripTd_${idx}`">
                                        <span :id="`tradebook_td_${idx}_name`">{{ item?.formattedInsName || item?.tradingSymbol }} </span>
                                        <span :id="`tradebook_td_${idx}_exch`" class="px-2 secondary-color text-[10px]">{{ item?.exch }}</span>
                                        <span v-if="currentIndex == idx" class="absolute mx-4 cursor-pointer right-0 flex top-[25%]">
                                            <button :id="`tradebook_td_${idx}_repeat_btn`" @click="MshowModal(item,item.transType == 'B' ? 0 : 1,'repeat')"
                                                class="px-3 py-1 text-xs text-white mr-2 bg-[#f75723] rounded">Repeat</button>
                                            <div class="relative flex text-left">
                                                <button :id="`tradebook_td_${idx}_more_btn`" type="button" class="inline-flex w-full items-center justify-center px-3 py-[3px] text-xs text-blue-500 border border-blue-500 rounded text-center bg-white dark:bg-[#181818]"  aria-expanded="true" aria-haspopup="true"
                                                    @click="showDropDown = !showDropDown;"> More
                                                </button>
                                                <transition v-if="showDropDown"
                                                    enter-active-class="transition ease-out duration-100"
                                                    enter-from-class="transform opacity-0 scale-95"
                                                    enter-to-class="transform opacity-100 scale-100"
                                                    leave-active-class="transition ease-in duration-75"
                                                    leave-from-class="transform opacity-100 scale-100"
                                                    leave-to-class="transform opacity-0 scale-95">
                                                    <div class="absolute right-0 z-10 mt-7 origin-top-right rounded-md w-[158px] bg-white shadow-lg dark:bg-[#1F1F1F] dark:text-[#bbb]"
                                                        role="menu" aria-orientation="vertical"
                                                        tabindex="-1" id="menu_Item"
                                                        :class="$common.getDropDownHeight(`scripTd_${idx}`) ? 'bottom-[25px]' : ''">
                                                        <div class="py-1" role="none">
                                                            <div class="flex items-center px-2 hover:bg-gray-50 hover:dark:bg-[#763ed71c]" @click="getOrderInfo(item)">
                                                                <div>
                                                                    <img :src="getIsTheme == 1 ? infoSvgDark : infoSvg" class="h-[16px] w-[16px] primary-color" alt="info">
                                                                </div>
                                                                <div>
                                                                    <a :id="`order_td_${idx}_info_btn`" class="primary-color dark:text-[#bbb block px-2 py-2 text-xs" role="menuitem" tabindex="-1">Info</a>
                                                                </div>
                                                            </div>
                                                            <div class="flex items-center px-2 hover:bg-gray-50 hover:dark:bg-[#763ed71c]" @click="$common.callChart(item) ">
                                                                <div>
                                                                    <img :src="getIsTheme == 1 ? chartsvgDark : chartSvg" class="h-[16px] w-[16px] primary-color" alt="chart">
                                                                </div>
                                                                <div>
                                                                    <a :id="`tradebook_td_${idx}_chart_btn`" class="primary-color dark:text-[#bbb] block px-2 py-2 text-xs" role="menuitem" tabindex="-1">Chart</a>
                                                                </div>
                                                            </div>
                                                            <div class="px-2 hover:bg-gray-50 hover:dark:bg-[#763ed71c]" @click="$common.addToMw(item,'orders')"  v-if="$store.state.pref.marketwatchType == 'My Watchlist'">
                                                                <div class="flex">
                                                                    <img :src="getIsTheme == 1 ? bookDark : book" alt="MW" class="[h-16px] w-[16px] object-scale-down">
                                                                    <span>
                                                                        <a :id="`tradebook_td_addToMw_${idx}`" class="whitespace-nowrap primary-color dark:text-[#bbb] block px-[10px] py-2 text-xs" role="menuitem" tabindex="-1"
                                                                        >Add to Watchlist</a>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </transition>
                                            </div>
                                        </span>
                                    </td>
                                    <td class=" text-left px-2 py-4 text-sm" :id="`tradebook_td_${idx}_pricetype`">
                                        {{ $common.getPcode(item) }} / {{ item?.priceType == 'L' ? 'LIMIT' : item?.priceType }} 
                                    </td>
                                    <td class=" text-right px-2 py-4 text-sm" :id="`tradebook_td_${idx}_qty`">
                                        <!-- {{ ` ${item["fillShares"] ? getFillShares(item) : '0'} / ${getLot(item)} `}} -->
                                        {{ item['qty'] }}
                                    </td>
                                    <td class="text-right px-2 py-4 text-sm font-semibold" :id="`tradebook_td_${idx}_price`">
                                        <span>
                                            {{ item.exchange == "CDS" || item.exchange == "BCD"
                                                ? parseFloat(item?.fillprc).toFixed(4)
                                                : parseFloat(item?.fillprc).toFixed(2)
                                            }}
                                        </span>
                                        
                                    </td>
                                    <td class="whitespace-nowrap text-center px-2 py-4 text-sm" :id="`tradebook_td_${idx}_status`">
                                        <button v-tippy="{ content: item?.rejectedReason, classes:'tooltipCusClass' }"
                                            class="px-3 text-[10px] rounded min-w-[100px] cursor-default green-btn-c tracking-[0.4px] min-h-[24px]">COMPLETE</button>
                                    </td>
                                </tr>
                                <tr class="text-center border-[0px]" v-if="getTradeBook.length == 0">
                                    <td class="px-6 py-4" colspan="10">No Orders Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div v-else class="text-center text-base p-5 calc-height-nodata flex items-center justify-center overflow-auto">
            <nodata :type="`Completed orders`"/>
        </div>
        <info-modal v-if="getIsOrderInfoDialog" :infoData="infoData"/>
    </div>
</template>

<script>
import nodata from '../no-data.vue'
import { mapGetters } from 'vuex'
// images
import chartSvg from "@/assets/img/chartSvg.svg"
import chartsvgDark from '@/assets/img/chartSvgDark.svg'
import book from '@/assets/img/dropdown/bookmark.svg'
import bookDark from '@/assets/img/dropdown/bookmarkDark.svg'
import upArrow from '@/assets/img/upArrow.svg'
import upArrowDark from '@/assets/img/upArrowDark.svg'
import downArrow from '@/assets/img/downArrow.svg'
import downArrowDark from '@/assets/img/downArrowDark.svg'
import infoSvg from "@/assets/img/infoLogo.svg"
import infoSvgDark from '@/assets/img/infoLogoDark.svg'
import infoModal from './info-model.vue'
export default {
    data() {
        return {
            currentIndex: -1,
            chartSvg,
            chartsvgDark,
            book,
            bookDark,
            tableHead: [
                { alignment: 'flex justify-start items-center', text: "Time",  sortable: true, value: "orderTime", class: "w-12 py-3.5 px-3 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-center items-center', text: "Type",  value: "displayName", sortable: true, class: "px-3 py-3.5 text-center text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-start items-center', text: "Instrument",  value: "formattedInsName", sortable: true, class: "px-3 py-3.5 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-start items-center', text: "Product", value: 'product', sortable: true, class: "px-3 py-3.5 text-left text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Qty.", value: "qty", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex flex-row-reverse items-center', text: "Price", value: "price", sortable: true, class: "px-3 py-3.5 text-right text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
                { alignment: 'flex justify-center items-center', text: "Status", value: "orderStatus", sortable: false, class: "px-3 py-3.5 text-center text-xs font-semibold secondary-color", isSort: false, isdesc: true, show: true },
            ],
            upArrow,
            upArrowDark,
            downArrow,
            downArrowDark,
            infoSvg,
            infoSvgDark,
            isHover:false,
            currentIdx: -1,
            pagination: {
                sortBy: null,
                descending: false
            },
            infoData: '',
            showDropDown: false
        }
    },
    components: { infoModal, nodata },
    computed: {
        ...mapGetters('order', ['getTradeBook']),
        ...mapGetters(['getSearch','getIsOrderInfoDialog']),
        ...mapGetters('pref',['getIsTheme']),
        filterData() {
            let data = this.getTradeBook
            return data.filter((post) => {
                return (
                    post.formattedInsName?.toLowerCase()?.includes(this.getSearch?.toLowerCase())
                );
            });
        },
    },
    methods: {
        async MshowModal(post, type, o_type) {
            this.$store.state.buysellpopup.showModal = true;
            this.$store.state.buysellpopup.bsselected = type;
            this.$store.commit('ow/setIsAddOrExit', o_type)
            this.$store.commit('ow/setCurrentScripData', post)
            if(o_type == 'modify' || o_type == 'repeat') {
                this.$store.commit('ow/setIsLocalPreference', false)
            } else {
                this.$store.commit('ow/setIsLocalPreference', true)
            }
            await this.$store.dispatch('ow/getContractInfo',{exchange:post?.exchange, token:post?.token})
            this.$store.commit('ow/setCurrentPage', this.$options.name)
            this.$store.dispatch('ow/setDefaultValues')
            setTimeout(() => {
                this.$store.dispatch('ow/getMargin')
            }, 50);
        },
        getLot(item) {
            let qty = item.exchange == 'MCX' ? item.qty / item.lotSize : item.qty
            return qty
        },

        getFillShares(item) {
            let fillShares = item.exchange == 'MCX' ? item.fillShares / item.lotSize : item.fillShares
            return fillShares
        },
        changeSort(column) {
            if (this.pagination.sortBy === column) {
                this.pagination.descending = !this.pagination.descending;
            } else {
                this.pagination.sortBy = column;
                this.pagination.descending = false;
            }
            this.getDataFromApi()
        },
        getDataFromApi() {
            return new Promise((resolve, reject) => {
                const { sortBy, descending } = this.pagination
                let items = this.getTradeBook
                if (sortBy) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy]
                        const sortB = b[sortBy]
                        if (descending) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }
                let headers = this.tableHead
                headers.findIndex((idex) => {
                    idex.value == sortBy ? (idex.isSort = true) : (idex.isSort = false);
                    idex.value == sortBy ? (idex.isdesc = descending) : "";
                });
            })
        },
        async getOrderInfo(item) {
            this.infoData = item
            this.$store.dispatch('order/getOrderHistory', { orderNo: item.orderNo })
            this.$store.commit('setOrderInfoDialog', true)
        },
    },
}
</script>